import "@css/app.css";

import Swiper from "swiper";
import { EffectFade, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";

const heroSlider = new Swiper(".js-hero", {
  modules: [EffectFade, Autoplay],
  effect: "fade",
  loop: true,
  speed: 1000,
  fadeEffect: {
    crossFade: true,
  },
  autoplay: {
    delay: 3000,
  },
});

// Put Alpine last so that it has access to other JS modules
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
